import React, { useState } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  TableHead,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import Page from '../components/Page';
import Iconify from '../components/Iconify';

import api from '../services/api';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

const columns = [
  { field: 'id', headerName: 'ID', width: 200 },
  { field: 'cpf', headerName: 'CPF', width: 200 },
  { field: 'descricao', headerName: 'Descrição', width: 200 },
  {
    field: 'dataPremiado',
    headerName: 'Data Resgate',
    width: 200
  }
];

export default function ConsultAwards() {
  const [cpf, setCpf] = useState('');
  const [awards, setAwards] = React.useState([]);

  const [openModal, setOpenModal] = React.useState(false);

  const consult = async () => {
    await api.get('/consultas/Premio').then((res) => {
      setAwards(res.data);
    });
  };

  React.useEffect(() => {
    consult();
  }, []);

  return (
    <Page title="Consultar Prêmios | PlenaPharma">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Consultar Prêmios
          </Typography>
        </Stack>
        <Stack direction="column" spacing={2}>
          <Card>
            {/*  <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>CPF</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Data</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {awards.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.cpf}</TableCell>
                      <TableCell>{row.descricao}</TableCell>
                      <TableCell>{row.dataPremiado}</TableCell>
                    </TableRow>
                  ))}
                </TableBody> 
              </Table> */}
            <div style={{ height: 900, width: '100%' }}>
              <DataGrid rows={awards} columns={columns} pageSize={20} rowsPerPageOptions={[20]} />
            </div>
          </Card>
        </Stack>
      </Container>
    </Page>
  );
}
