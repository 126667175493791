import React from 'react';
import Routes from './routes/index';
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import AppProvider from './hooks';

export default function App() {
  return (
    <AppProvider>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <Routes />
      </ThemeConfig>
    </AppProvider>
  );
}
