import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import ConsultCpf from '../pages/ConsultCpf';
import ConsultAwards from '../pages/ConsultAwards';
import Home from '../pages/Home';
import RegisterAwards from '../pages/RegisterAwards';

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route isPrivate path="/dashboard" exact component={Dashboard} />
      <Route path="/login" exact component={Login} />
      <Route isPrivate path="/dashboard/consultar-cpf" exact component={ConsultCpf} />
      <Route isPrivate path="/dashboard/consultar-premios" exact component={ConsultAwards} />
      <Route isPrivate path="/dashboard/cadastrar-premiacao" exact component={RegisterAwards} />
    </Switch>
  );
}

export default Routes;
