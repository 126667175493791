import React from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TableHead,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { validateCPF } from 'validations-br';
import mask from '../utils/mask';

import Page from '../components/Page';

import api from '../services/api';

export default function ConsultAwards() {
  const [dataUser, setDataUser] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);

  const validatePhone =
    /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;

  const RegisterSchema = Yup.object().shape({
    cpf: Yup.string('Digite seu CPF')
      .test('is-cpf', 'CPF não é válido', (value) => validateCPF(value))
      .required('CPF é obrigatório'),
    celular: Yup.string()
      .matches(validatePhone, 'Número de telefone não é válido')
      .required('Número de telefone é obrigatório')
  });

  const formik = useFormik({
    initialValues: {
      cpf: '',
      nome: '',
      celular: '',
      email: '',
      endereco: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values, { resetForm }) => {
      await api.post('/cadastro/sorteioCliente', values).then((res) => {
        if (res.data === 'Cliente já Foi contemplado') {
          resetForm();
          alert(res.data);
        } else {
          setDataUser(res.data);
          resetForm();
          setOpenModal(true);
        }
      });
    }
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, values } = formik;

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleStatus = async (item) => {
    const data = {
      ...item,
      status: 'Resgatado'
    };
    await api.put('/update/Premio', data).then((resp) => {
      alert('Status Alterado com sucesso!');
      setOpenModal(false);
    });
  };

  return (
    <Page title="Cadastro de Premiação  | PlenaPharma">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Cadastro de Premiação
          </Typography>
        </Stack>
        <Stack spacing={2}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <Stack spacing={4}>
                <TextField
                  label="CPF"
                  {...getFieldProps('cpf')}
                  error={Boolean(touched.cpf && errors.cpf)}
                  helperText={touched.cpf && errors.cpf}
                />

                <TextField
                  label="Nome"
                  {...getFieldProps('nome')}
                  error={Boolean(touched.nome && errors.nome)}
                  helperText={touched.nome && errors.nome}
                />

                <TextField
                  label="Celular"
                  {...getFieldProps('celular')}
                  error={Boolean(touched.celular && errors.celular)}
                  helperText={touched.celular && errors.celular}
                />

                <TextField
                  label="Email"
                  {...getFieldProps('email')}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />

                <TextField
                  label="Endereço"
                  {...getFieldProps('endereco')}
                  error={Boolean(touched.endereco && errors.endereco)}
                  helperText={touched.endereco && errors.endereco}
                />
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ my: 2 }}
              >
                <LoadingButton
                  loading={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Cadastrar
                </LoadingButton>
              </Stack>
            </Form>
          </FormikProvider>
        </Stack>
      </Container>
      <Dialog onClose={handleClose} open={openModal} fullWidth maxWidth="lg">
        <DialogTitle>Nome: {dataUser?.dadosUsuario?.nome}</DialogTitle>
        <List>
          <ListItem>
            <ListItemText primary="Telefone" />
            <ListItemText primary={dataUser?.dadosUsuario?.celular} />
          </ListItem>
          <ListItem>
            <ListItemText primary="CPF" />
            <ListItemText primary={dataUser?.dadosUsuario?.cpf} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Email" />
            <ListItemText primary={dataUser?.dadosUsuario?.email} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Endereço" />
            <ListItemText primary={dataUser?.dadosUsuario?.endereco} />
          </ListItem>
        </List>
        <TableContainer sx={{ minWidth: 800 }}>
          <Typography variant="h4" gutterBottom style={{ margin: 20 }}>
            Lista Prêmios
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Descrição</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={dataUser.premioUsuario?.id}>
                <TableCell>{dataUser?.premioUsuario?.descricao}</TableCell>
                <TableCell>{dataUser.premioUsuario?.dataPremiado}</TableCell>
                <TableCell>{dataUser.premioUsuario?.status}</TableCell>
                <TableCell>
                  {' '}
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() => handleStatus(dataUser.premioUsuario)}
                  >
                    Resgatar
                  </LoadingButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <LoadingButton size="large" type="submit" variant="contained" onClick={handleClose}>
          Fechar
        </LoadingButton>
      </Dialog>
    </Page>
  );
}
