import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Stack, TextField, Container, Typography, Dialog, DialogTitle } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Confetti from 'react-dom-confetti';
import { Wheel } from 'react-custom-roulette';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import { validateCPF } from 'validations-br';
import Page from '../components/Page';
import mask from '../utils/mask';
import api from '../services/api';

import logoImg from '../assets/Roleta.png';

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  flexDirection: 'column',
  padding: theme.spacing(8, 0)
}));

const config = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.12,
  duration: 3000,
  stagger: 3,
  width: '10px',
  height: '10px',
  perspective: '500px',
  colors: ['#a864fd', '#29cdff', '#78ff44', '#ff718d', '#fdff6a']
};

const backgroundColors = ['#67C4A9', '#567DBD', '#B1B1B1'];
const textColors = ['#0b3351'];
const outerBorderColor = '#eeeeee';
const outerBorderWidth = 10;
const innerBorderColor = '#30261a';
const innerBorderWidth = 0;
const innerRadius = 0;
const radiusLineColor = '#eeeeee';
const radiusLineWidth = 8;
const fontSize = 17;
const textDistance = 60;

export default function Home() {
  const [active, setActive] = React.useState(false);
  const [names, setNames] = React.useState([]);
  const [visible, setVisible] = React.useState(false);
  const [cpf, setCpf] = React.useState(null);
  const [visibleButton, setVisibleButton] = React.useState(false);
  const [visibleText, setVisibleText] = React.useState(false);
  const [premio, setPremio] = React.useState('');
  const [nameUser, setNameUser] = React.useState('');
  const [visibleWhatsApp, setVisibleWhatsApp] = React.useState(false);

  const getPremios = async () => {
    await api.get('/consultas/PremiosDisponiveis').then((response) => {
      setNames(response.data);
    });
  };

  React.useEffect(() => {
    getPremios();
  }, []);

  const [mustSpin, setMustSpin] = React.useState(false);
  const [prizeNumber, setPrizeNumber] = React.useState(0);

  const handleClose = () => {
    setVisibleWhatsApp(false);
  };

  const handleWhats = async () => {
    location.href = 'https://api.whatsapp.com/send/?phone=5579999790047';
  };

  const LoginSchema = Yup.object().shape({
    cpf: Yup.string('Digite seu cpf')
      .test('is-cpf', 'CPF não é válido', (value) => validateCPF(value))
      .required('CPF é obrigatorio')
  });

  const formik = useFormik({
    initialValues: {
      cpf: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      setVisibleButton(true);

      await api
        .get('/consultas/Cliente', {
          headers: {
            cpf: values.cpf.toString().replace(/\.|-/gm, '')
          }
        })
        .then((res) => {
          if (res.data) {
            setActive(false);
            setVisible(true);

            setNameUser(res.data.dadosUsuario.nome);

            function number(name, index) {
              setPrizeNumber(index);
              return name === res.data.premioUsuario.descricao;
            }
            console.log(names.find(number));
            setPremio(names.find(number));
            setMustSpin(true);
          } else {
            setVisibleWhatsApp(true);
            console.log('ok');
          }
        });
    }
  });

  return (
    <RootStyle title="Sorteio | PlenaPharma">
      <Container maxWidth="sm">
        <ContentStyle>
          <form autoComplete="off" onSubmit={formik.handleSubmit}>
            <Stack>
              <img
                src={logoImg}
                style={{
                  width: '100%',
                  borderRadius: 60,
                  marginBottom: 20
                }}
                alt="PlenaPharma"
              />
            </Stack>
            <Confetti active={active} config={config} />
            {visibleText ? (
              <>
                <Stack alignItems="center" justifyContent="center" spacing={2}>
                  <Typography variant="h4" gutterBottom>
                    {nameUser}
                  </Typography>
                </Stack>
              </>
            ) : (
              <>
                <TextField
                  fullWidth
                  label="CPF"
                  name="cpf"
                  disabled={visibleButton}
                  value={mask(formik.values.cpf, '999.999.999-99')}
                  onChange={formik.handleChange}
                  style={{
                    marginBottom: 10
                  }}
                  error={Boolean(formik.touched.cpf && formik.errors.cpf)}
                  helperText={formik.touched.cpf && formik.errors.cpf}
                />
              </>
            )}
            {visible && (
              <Stack alignItems="center" justifyContent="center" spacing={2}>
                <Wheel
                  mustStartSpinning={mustSpin}
                  prizeNumber={prizeNumber}
                  data={names.map((item) => ({ option: item }))}
                  backgroundColors={backgroundColors}
                  textColors={textColors}
                  fontSize={fontSize}
                  outerBorderColor={outerBorderColor}
                  outerBorderWidth={outerBorderWidth}
                  innerRadius={innerRadius}
                  innerBorderColor={innerBorderColor}
                  innerBorderWidth={innerBorderWidth}
                  radiusLineColor={radiusLineColor}
                  radiusLineWidth={radiusLineWidth}
                  textDistance={textDistance}
                  onStopSpinning={() => {
                    setVisibleText(true);
                    setActive(true);
                    setMustSpin(false);
                  }}
                />
              </Stack>
            )}
            <Confetti active={active} config={config} />
            {visibleButton ? (
              <></>
            ) : (
              <LoadingButton fullWidth size="large" type="submit" variant="contained">
                Consulte seu Prêmio
              </LoadingButton>
            )}

            {visibleText ? (
              <Stack alignItems="center" justifyContent="center" spacing={2}>
                <Typography variant="h5">Parabéns você ganhou {premio}!!!</Typography>
              </Stack>
            ) : (
              <></>
            )}
          </form>
        </ContentStyle>
      </Container>
      <Confetti active={active} config={config} />
      <Dialog onClose={handleClose} open={visibleWhatsApp} fullWidth>
        <Stack alignItems="center" justifyContent="center" spacing={2} style={{ padding: 20 }}>
          <Typography variant="h5">
            "Infelizmente você não está elegível na campanha. Entre em contato com o nosso
            atendimento para mais informações. Obrigado."
          </Typography>
          <LoadingButton size="large" type="submit" variant="contained" onClick={handleWhats}>
            Participar da Promoção
          </LoadingButton>
        </Stack>
        <LoadingButton size="large" type="submit" variant="contained" onClick={handleClose}>
          Fechar
        </LoadingButton>
      </Dialog>
    </RootStyle>
  );
}
