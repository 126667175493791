import React from 'react';

const AuthContext = React.createContext({
  signIn: () => console.log('Not implemented yet'),
  signOut: () => console.log('Not implemented yet')
});

export function AuthProvider({ children }) {
  const [loading, setLoading] = React.useState(false);

  const [data, setData] = React.useState(() => {
    const user = localStorage.getItem('@User');

    if (user) {
      return { user };
    }

    return {};
  });

  const signIn = async (username, password) => {
    console.log(password === 'password');
    if (username === 'petson-pp' && password === 'password') {
      console.log('aqui');
      localStorage.setItem('@User', JSON.stringify(username));
      setData({
        user: username
      });
    }
  };

  const signOut = React.useCallback(() => {
    localStorage.clear();
    setData({});
  }, []);

  return (
    <AuthContext.Provider value={{ user: data.user, signIn, signOut, loading }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = React.useContext(AuthContext);

  if (!context) throw new Error('useAuth must be used within an AuthProvider');

  return context;
}
