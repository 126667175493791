import { Box, Container } from '@mui/material';
import Page from '../components/Page';

export default function Dashboard() {
  return (
    <Page title="Dashboard | PlenaPharma">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }} />
      </Container>
    </Page>
  );
}
