import React, { useState } from 'react';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  OutlinedInput,
  InputAdornment,
  TableHead,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DataGrid } from '@mui/x-data-grid';
import mask from '../utils/mask';

import Page from '../components/Page';
import Iconify from '../components/Iconify';

import api from '../services/api';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

const columns = [
  { field: 'nome', headerName: 'Nome', width: 200 },
  { field: 'cpf', headerName: 'CPF', width: 200 },
  {
    field: 'email',
    headerName: 'Email',
    width: 200
  },
  {
    field: 'endereco',
    headerName: 'Endereço',
    width: 200
  }
];

export default function ConsultCpf() {
  const [cpf, setCpf] = useState('');
  const [listClient, setListClient] = React.useState([]);
  const [client, setClient] = React.useState([]);

  const [openModal, setOpenModal] = React.useState(false);

  const consultListClient = async () => {
    await api.get('/consultas/Cliente').then((res) => {
      setListClient(res.data);
    });
  };

  const consultClientCpf = async () => {
    await api
      .get('/consultas/Cliente', {
        headers: {
          cpf
        }
      })
      .then((res) => {
        setClient(res.data);
        setOpenModal(true);
      });
  };

  React.useEffect(() => {
    consultListClient();
  }, []);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleStatus = async (item) => {
    const data = {
      ...item,
      status: 'Resgatado'
    };
    await api.put('/update/Premio', data).then((resp) => {
      alert('Status Alterado com sucesso!');
      setOpenModal(false);
    });
  };

  return (
    <Page title="Consultar CPF | PlenaPharma">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Consultar CPF
          </Typography>
        </Stack>
        <Stack direction="column" spacing={2}>
          <Card>
            <Stack alignItems="center" justifyContent="center" spacing={2} style={{ padding: 20 }}>
              <SearchStyle
                value={cpf}
                onChange={(e) => setCpf(e.target.value)}
                placeholder="Consultar CPF..."
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                  </InputAdornment>
                }
              />
              <LoadingButton
                size="large"
                type="submit"
                variant="contained"
                onClick={consultClientCpf}
              >
                Consultar
              </LoadingButton>
            </Stack>
          </Card>
          <Card>
            <div style={{ height: 900, width: '100%' }}>
              <DataGrid
                rows={listClient}
                columns={columns}
                pageSize={20}
                rowsPerPageOptions={[20]}
                getRowId={(r) => r.cpf}
              />
            </div>
          </Card>
        </Stack>
      </Container>
      <Dialog onClose={handleClose} open={openModal} fullWidth maxWidth="lg">
        <DialogTitle>Nome: {client?.dadosUsuario?.nome}</DialogTitle>
        <List>
          <ListItem>
            <ListItemText primary="Telefone" />
            <ListItemText primary={client?.dadosUsuario?.celular} />
          </ListItem>
          <ListItem>
            <ListItemText primary="CPF" />
            <ListItemText primary={client?.dadosUsuario?.cpf} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Email" />
            <ListItemText primary={client?.dadosUsuario?.email} />
          </ListItem>
          <ListItem>
            <ListItemText primary="Endereço" />
            <ListItemText primary={client?.dadosUsuario?.endereco} />
          </ListItem>
        </List>
        <TableContainer sx={{ minWidth: 800 }}>
          <Typography variant="h4" gutterBottom style={{ margin: 20 }}>
            Lista Prêmios
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Descrição</TableCell>
                <TableCell>Data</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Ações</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={client.premioUsuario?.id}>
                <TableCell>{client?.premioUsuario?.descricao}</TableCell>
                <TableCell>{client.premioUsuario?.dataPremiado}</TableCell>
                <TableCell>{client.premioUsuario?.status}</TableCell>
                <TableCell>
                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={() => handleStatus(client.premioUsuario)}
                  >
                    Resgatar
                  </LoadingButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <LoadingButton size="large" type="submit" variant="contained" onClick={handleClose}>
          Fechar
        </LoadingButton>
      </Dialog>
    </Page>
  );
}
